import { CForm, CFormFloating, CFormInput, CFormLabel } from "@coreui/react";
import React from "react";
import PropTypes from 'prop-types';

const BankView = (props) => {

    const { bankingDetails } = props;

    {/*
        id
        userid
        accNo
        ifscCode
        branch
        cancelCheckUrl
        panCardUrl
        addressProofUrl
    */}

    const renderBankView = () => {
       return(
       <div className="show-info-wrapper">
          <div className="row">
            <div className="col-md-6">
              <div className="show-info-text">
                 <label>Bank Name</label>
                <span>{bankingDetails?.bank_name ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="show-info-text">
                 <label>Account Number</label>
                <span>{bankingDetails?.account_number ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="show-info-text">
                <label>IFSC Code</label>
                <span>{bankingDetails?.ifsc_code ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="show-info-text">
                <label>Branch Name</label>
                <span>{bankingDetails?.branch ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="show-info-text">
                <label>Cancel Check</label>
                <span>{bankingDetails?.cancel_check_url ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="show-info-text">
                <label>Pan Card</label>
                <span>{bankingDetails?.pan_card_url ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="show-info-text">
                <label>Address Proof</label>
                <span>{bankingDetails?.address_proof_url ?? '--'}</span>
              </div>
            </div>
          </div>
        </div>
       );
    };

    return(
        
        <>
            {renderBankView()}
        </>
);
};

export default BankView;

BankView.propTypes = {
    bankingDetails: PropTypes.any,
    // updateBankDetails: PropTypes.func.isRequired
};
