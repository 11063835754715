import React from 'react';
import { CFooter } from '@coreui/react';
import { UrlConstants } from 'src/utils/UrlConstants';

const AppFooter = () => {
  return (
    <CFooter>
      <div>
        <a href="https://www.aristonitservices.com/" target="_blank" rel="noopener noreferrer">
           Powered By : Ariston IT Services
        </a>
        {/* <span className="ms-1">&copy; 2023 creativeLabs.</span> */}
      </div>
      <div className="ms-auto">
        <a href={UrlConstants.siteUrl.userSite} target="_blank" rel="noopener noreferrer">
           Build Busines Advertising
        </a>
        <span className="ms-1">&copy; 2024.</span>
      </div>
      
    </CFooter>
  );
};

export default React.memo(AppFooter);
