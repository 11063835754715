import { CButton, CCard, CCardBody, CCardHeader, CCardLink, CCol, CRow } from '@coreui/react';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toaster, UserProfile } from 'src/components';
import { AuthContext } from 'src/context';
import { userList } from 'src/mockData/userData';
import { UrlConstants } from 'src/utils/UrlConstants';

const MyProfile = () => {
    const { user, toasterMsg, setToasterMsg } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(()=>{
      return () => {
        setToasterMsg({error:false, message:''});
      };
    },[])

    const navigatToEdit = () => {
        navigate(UrlConstants.routeUrls.editProfile);
    };

    return (
        <CRow>
        <Toaster show={!!toasterMsg.message} colorScheme={toasterMsg.error ? 'danger' : 'success'} message={toasterMsg.message} onToasterClose={() => setToasterMsg('')} autoHide={true}/>
         <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                <strong>My Profile</strong>
                <CButton className='btn btn-primary m-2' onClick={navigatToEdit}>Edit</CButton>
            </CCardHeader>
            <CCardBody>
                    <UserProfile userDetails={user} />
                </CCardBody>
           </CCard>
         </CCol>
        </CRow>
    );
};

export default MyProfile;
