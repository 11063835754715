import { CForm, CFormInput, CFormLabel, CInputGroup,CFormFloating } from "@coreui/react";
import React, { useContext } from "react";
import PropTypes from 'prop-types'; // Import prop-types
import msScanner from 'src/assets/images/scanner/msScanner.jpeg';
import { UserTypeConstant } from "src/utils/Constants";
import { AuthContext } from "src/context";

const UserBasicInfo = (props) => {
    const { updateUserDetails, userDetails, userType, isEdit = false, invalidFields } = props;
    const { user } = useContext(AuthContext);
    console.log('userDetails***', userDetails);

    {/* field
        firstName
        lastName
        email(loginID)
        password
        confirm password
        adharNo
        listedBy(userID name) 
        // roleId - auto entered(don't show)
    */}

    return(
        // <CForm>
            <div className="from-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="text" id="firstName" placeholder="First Name" value={userDetails?.first_name} onChange={updateUserDetails}  name="first_name"
                         feedbackInvalid="Please enter a first name."
                         required
                         tooltipFeedback  
                        />
                        <CFormLabel htmlFor="firstName">First Name</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="text" id="lastName" placeholder="Last Name"  value={userDetails?.last_name} onChange={updateUserDetails}
                         feedbackInvalid="Please enter a last name." name="last_name"
                         required
                         tooltipFeedback  
                        />
                        <CFormLabel htmlFor="lastName">Last Name</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="email" id="email" placeholder="Email Address"  value={userDetails?.email} onChange={updateUserDetails} name="email"
                         feedbackInvalid="Please enter a email."
                         required
                         tooltipFeedback  
                         
                        />
                        <CFormLabel htmlFor="email">Email Address</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="number" id="phoneNumber" placeholder="Phone Number"  value={userDetails?.phone_number } onChange={updateUserDetails} name="phone_number"
                         feedbackInvalid={invalidFields?.phoneNumber? "Please enter a valid 10 digit phone number.": "Please enter a phone number."}
                         required
                         tooltipFeedback  
                         invalid={invalidFields?.phoneNumber}
                         
                        />
                        <CFormLabel htmlFor="phoneNumber">Phone Number</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                         <CFormFloating className="mb-3">
                        <CFormInput type="password" id="password" placeholder="Password"  value={userDetails?.password} onChange={updateUserDetails} 
                         feedbackInvalid={invalidFields?.password ? "Password must be at least 6 characters long.":"Please enter password"} name="password"
                         required
                         tooltipFeedback 
                         invalid={invalidFields?.password}
                        />
                        <CFormLabel htmlFor="password">Password</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="password" id="cmpPassword" placeholder="Confirm Password"  value={userDetails?.cmpPassword} onChange={updateUserDetails} name="c_password"
                        feedbackInvalid={invalidFields?.cPassword ?"Password & Confirm password should match." : "Please enter confirm passord"}
                        required
                        tooltipFeedback 
                        invalid={invalidFields?.cPassword}
                        />
                        <CFormLabel htmlFor="cmpPassword">Confirm Password</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="number" id="adharNo" placeholder="Adhar Number"  value={userDetails?.aadhar_number} onChange={updateUserDetails} name="aadhar_number"/>
                        <CFormLabel htmlFor="adharNo">Aadhaar Number</CFormLabel>
                        </CFormFloating>
                    </div>
                    
                    {!isEdit && <div className="col-md-6">
                         <CFormFloating className="mb-3">
                            <CFormInput type="text" id="listedBy" placeholder="Refered By"
                                // value={userDetails?.listedBy}
                                onChange={updateUserDetails} value={`${user?.first_name} ${user?.last_name}`} readOnly />
                        <CFormLabel htmlFor="listedBy">Refered By</CFormLabel>
                        </CFormFloating>
                    </div>}
                    <div className="col-md-6">
                         {(userType === UserTypeConstant.vendor && !isEdit) && <div className="mb-3">
                           <img src={msScanner} alt="" className="scannerImg" />
                        </div>}
                    </div>
                </div>
            </div>
            
        // </CForm>
    );
};

export default UserBasicInfo;

UserBasicInfo.propTypes = {
    userDetails: PropTypes.any,
    updateUserDetails: PropTypes.func.isRequired,
    userType: PropTypes.any,
    isEdit: PropTypes.boolean,
    invalidFields: PropTypes.any
};
