import React, { useEffect, useState } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCol,
  CFormInput,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { DocsExample, TableActionButtons, TableHeaders } from 'src/components';
import { PageSearchHeader } from 'src/components';
import { subscriptionList } from 'src/mockData/userData';
import { UserSubscriptionHeaders } from 'src/utils/Constants';

const UserSubscriptions = () => {

  const [subscriptions, setSubscriptions] = useState([]);
  
  useEffect(()=>{
    getSubscriptionsList();
  },[]);

  const getSubscriptionsList = () => {
    setSubscriptions(subscriptionList);
  };
//   Agent Name, Vendor Name,  package name, package amount, commision amount.
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader style={{display:'flex',justifyContent:'space-between'}}>
            <strong>Subscriptions</strong>
          </CCardHeader>
          <CCardBody>
            <PageSearchHeader />
            <div className='table-responsive'>
              <CTable striped hover bordered className="mt-4">
                <TableHeaders haederArr={UserSubscriptionHeaders} />
                <CTableBody>
                  {
                    subscriptionList?.map((sub, index) => {
                      return( 
                      <CTableRow key={sub.id}>
                        <CTableHeaderCell scope="row">{sub.id}</CTableHeaderCell>
                        <CTableDataCell>{sub.packageName}</CTableDataCell>
                        <CTableDataCell>{sub.subscriptionAmount}</CTableDataCell>
                        <CTableDataCell>{sub.subscriptionDate}</CTableDataCell>
                        <CTableDataCell>{sub.dueDate}</CTableDataCell>
                       </CTableRow>);
                    })
                  }
                  
                </CTableBody>
              </CTable>
              </div>
            {/* </DocsExample> */}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default UserSubscriptions;
