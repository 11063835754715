import { UserTypeConstant } from "../utils/Constants";
import Logo from '../assets/images/BB-Logo.jpg'
import OfferIcon from '../assets/images/offer-icon.jpeg'


export const mockUsers = [
    { userName: 'admin', password: '123123', type: UserTypeConstant.admin },
    { userName: 'agent', password: '123123', type: UserTypeConstant.agent },
    { userName: 'dis', password: '123123', type: UserTypeConstant.agent },

    // Add more mock users as needed
];


export const userList = [
    {
        id: 1,
        firstName: 'Mahesh',
        lastName:'Sapkal',
        phone: 1234567890,
        email: 'ms@gmail.com',
        userType: 'agent',
        adharNo: 12344
    },
    {
        id: 2,
        name: 'Sarthak Sapkal',
        phone: 1234567890,
        email: 'ss@gmail.com',
        userType: 'agent'
    },
    {
        id: 3,
        name: 'Naitik Sapkal',
        phone: 1234567890,
        email: 'ns@gmail.com',
        userType: 'agent'
    },
    {
        id: 4,
        name: 'Yash Sapkal',
        phone: 1234567890,
        email: 'ys@gmail.com',
        userType: 'agent'
    },
    {
        id: 5,
        name: 'Mack',
        phone: 1234567890,
        email: 'ms@gmail.com',
        userType: 'admin'
    },
    {
        id: 6,
        name: 'Mack distributor',
        phone: 1234567890,
        email: 'distributor1@gmail.com',
        userType: 'distributor'
    },
    {
        id: 7,
        name: 'Mack',
        phone: 1234567890,
        email: 'distributor2@gmail.com',
        userType: 'distributor'
    }


];

export const mockBusinessList = [
    {
        id: 1,
        shopName: 'Suraj Gents Parlor',
        shopLogUrl: '',
        description: 'Hair saloon',
        userId: 1,
        ownerName: 'Umesh Sapkal',
        shopPicUrl: '',
        shopPicThumbUrl: '',
        openingAt: '6 am',
        closeAt: '11 pm',
        operatingHrs: 9,
        shopCloseDay: 'Saturday',
        address: 'Kumbhoj',
        locationLink: '',
        state: 'Maharashtra',
        district: 'Kolhapur',
        tahsil: 'Hatkanangle',
        city: 'Kumbhoj',
        pincode: 416111,
        ownerPhone: 1234567890,
        shopPhone: 987654321,
        shopRegNo: 'EMPS1234',
        shopMail: 'ss@gmail.com',
        shopCatlogUrl: '',
        offerIconUrl: '',
        whatsAppNumber: 1234567890,
        facebookUrl: '',
        instaUrl: '',
        images: [
            'img1',
            'img2'
        ],
        thumbImages: [
            'img1', 'img2'
        ],
        shopRatig: 5,
        viewersCount: 7,
        categoryId: 1,
        packageName: 'basic',
        packageAmount: 5000,
        dueDate: '12/03/2025'
    },
    {
        id: 2,
        shopName: 'Example Shop 2',
        shopLogUrl: '',
        description: 'Description for Example Shop 2',
        userId: 2,
        ownerName: 'Owner 2',
        shopPicUrl: '',
        shopPicThumbUrl: '',
        openingAt: '9 am',
        closeAt: '8 pm',
        operatingHrs: 11,
        shopCloseDay: 'Sunday',
        address: 'Example Address 2',
        locationLink: '',
        state: 'Example State 2',
        district: 'Example District 2',
        tahsil: 'Example Tahsil 2',
        city: 'Example City 2',
        pincode: 222222,
        ownerPhone: 2222222222,
        shopPhone: 3333333333,
        shopRegNo: 'REG2222',
        shopMail: 'example2@example.com',
        shopCatlogUrl: '',
        offerIconUrl: '',
        whatsAppNumber: 2222222222,
        facebookUrl: '',
        instaUrl: '',
        images: [
            'img1',
            'img2'
        ],
        thumbImages: [
            'img1', 'img2'
        ],
        shopRatig: 4,
        viewersCount: 5,
        categoryId: 2,
        packageName: 'basic',
        packageAmount: 5000,
        dueDate: '10/03/2025'
    },
    // Object 3
    {
        id: 3,
        shopName: 'Example Shop 3',
        shopLogUrl: '',
        description: 'Description for Example Shop 3',
        userId: 3,
        ownerName: 'Owner 3',
        shopPicUrl: '',
        shopPicThumbUrl: '',
        openingAt: '8 am',
        closeAt: '10 pm',
        operatingHrs: 14,
        shopCloseDay: 'Monday',
        address: 'Example Address 3',
        locationLink: '',
        state: 'Example State 3',
        district: 'Example District 3',
        tahsil: 'Example Tahsil 3',
        city: 'Example City 3',
        pincode: 333333,
        ownerPhone: 3333333333,
        shopPhone: 4444444444,
        shopRegNo: 'REG3333',
        shopMail: 'example3@example.com',
        shopCatlogUrl: '',
        offerIconUrl: '',
        whatsAppNumber: 3333333333,
        facebookUrl: '',
        instaUrl: '',
        images: [
            'img1',
            'img2'
        ],
        thumbImages: [
            'img1', 'img2'
        ],
        shopRatig: 3,
        viewersCount: 8,
        categoryId: 3,
        packageName: 'basic',
        packageAmount: 10000,
        dueDate: '12/12/2024'
    },

    // Object 4
    {
        id: 4,
        shopName: 'Example Shop 4',
        shopLogUrl: '',
        description: 'Description for Example Shop 4',
        userId: 4,
        ownerName: 'Owner 4',
        shopPicUrl: '',
        shopPicThumbUrl: '',
        openingAt: '10 am',
        closeAt: '6 pm',
        operatingHrs: 8,
        shopCloseDay: 'Wednesday',
        address: 'Example Address 4',
        locationLink: '',
        state: 'Example State 4',
        district: 'Example District 4',
        tahsil: 'Example Tahsil 4',
        city: 'Example City 4',
        pincode: 444444,
        ownerPhone: 4444444444,
        shopPhone: 5555555555,
        shopRegNo: 'REG4444',
        shopMail: 'example4@example.com',
        shopCatlogUrl: '',
        offerIconUrl: '',
        whatsAppNumber: 4444444444,
        facebookUrl: '',
        instaUrl: '',
        images: [
            'img1',
            'img2'
        ],
        thumbImages: [
            'img1', 'img2'
        ],
        shopRatig: 4,
        viewersCount: 6,
        categoryId: 4,
        packageName: 'pro',
        packageAmount: 7000,
        dueDate: '12/03/2025'
    },

    // Object 5
    {
        id: 5,
        shopName: 'Example Shop 5',
        shopLogUrl: '',
        description: 'Description for Example Shop 5',
        userId: 5,
        ownerName: 'Owner 5',
        shopPicUrl: '',
        shopPicThumbUrl: '',
        openingAt: '7 am',
        closeAt: '9 pm',
        operatingHrs: 14,
        shopCloseDay: 'Thursday',
        address: 'Example Address 5',
        locationLink: '',
        state: 'Example State 5',
        district: 'Example District 5',
        tahsil: 'Example Tahsil 5',
        city: 'Example City 5',
        pincode: 555555,
        ownerPhone: 5555555555,
        shopPhone: 6666666666,
        shopRegNo: 'REG5555',
        shopMail: 'example5@example.com',
        shopCatlogUrl: '',
        offerIconUrl: '',
        whatsAppNumber: 5555555555,
        facebookUrl: '',
        instaUrl: '',
        images: [
            'img1',
            'img2'
        ],
        thumbImages: [
            'img1', 'img2'
        ],
        shopRatig: 5,
        viewersCount: 10,
        categoryId: 5,
        packageName: 'basic',
        packageAmount: 5000,
        dueDate: '31/03/2025'
    }

];

export const mockShopDetails = {
    owner_id: '1',
    category_id: 'Mobile',
    business_name:'Kiran Mobile',
    business_description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    close_day: 'Monday',
    logo_url: Logo,
    thumbnail_url: '',
    image_url: '',
    wp_url: '+91-8888888888',
    insta_url: 'https://www.instagram.com/',
    fb_url: 'https://www.instagram.com/',
    offer_icon_url: OfferIcon,
    catelog_url: 'https://coreui.io/react',
    gst_number: '27MLYGK1082D3Z7',
    registration_number:'',
    address:'8 Oracle Tower, Sri Shivshambho Tirtha Chowk, (Bheda Chowk) Karad, District Satara, Maharashtra, India',
    city: 'Karad',
    tehsil: 'Karad',
    district: 'Satara',
    state: 'Maharashtra',
    pin_code: '416008',
    location_url:'https://www.google.com/maps?q=kolhapur',
    viewers_count: '',
    shop_rating: '',
    opening_time: '9:00 AM',
    closing_time: '8:00 PM',
    active_status: 'Pending',
    images: [

    ],
    contacts:[]
};

export const commisionsList = [
    {
        id: 1,
        agentName: 'agen1',
        vendorName: 'Vendor 1',
        packageName: 'basic',
        packageAmount: 5000,
        commisionAmount: 100,
    },
    {
        id: 2,
        agentName: 'agent1',
        vendorName: 'Vendor 2',
        packageName: 'premimum',
        packageAmount: 7000,
        commisionAmount: 200,
    },
    {
        id: 3,
        agentName: 'agent-2',
        vendorName: 'Vendor 3',
        packageName: 'basic',
        packageAmount: 5000,
        commisionAmount: 100,
    },
    {
        id: 4,
        agentName: 'agent-4',
        vendorName: 'Vendor 4',
        packageName: 'basic',
        packageAmount: 5000,
        commisionAmount: 100,
    }
];

export const subscriptionList = [
    {
        id:1,
        packageName:'Basic',
        subscriptionAmount: 5000,
        subscriptionDate:'12/03/2024',
        dueDate: '12/03/2025'
    },
    {
        id:2,
        packageName:'Plus',
        subscriptionAmount: 7000,
        subscriptionDate:'12/03/2024',
        dueDate: '12/03/2025'
    },
    {
        id:3,
        packageName:'Pro',
        subscriptionAmount: 10000,
        subscriptionDate:'12/03/2024',
        dueDate: '12/03/2025'
    },
    {
        id:4,
        packageName:'Basic',
        subscriptionAmount: 3000,
        subscriptionDate:'12/03/2024',
        dueDate: '12/03/2025'
    },
];

export const bankData =  {
    id: 1,
    userid: 1,
    accNo: 123445,
    ifscCode: 'IFSC123',
    branch: 'htk',
    cancelCheckUrl: 'test',
    panCardUrl: 'test',
    addressProofUrl: 'test'
};
