import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react';
import CIcon from '@coreui/icons-react';

import { AppSidebarNav } from './AppSidebarNav';
import { sygnet } from 'src/assets/brand/sygnet';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import CompanyLogo from 'src/assets/images/BB-Logo.jpg';
// sidebar nav config
// import navigation from '../_nav';
import {_admin_nav, _agent_nav, _vendor_nav} from '../_nav';
import { AuthContext } from 'src/context';


const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const [navigations, setNavigations] = useState();
  const { token, user } = useContext(AuthContext);
  // let navigation = _vendor_nav;
  const rolls = ['admin', 'agent', 'vendor'];
  //const userRoll = rolls[3];
  const userRoll = user?.role_id;


  useEffect(()=>{
    // token && setNavigationPannel();
    setNavigationPannel();

  },[token]);

  const setNavigationPannel = () => {
    switch(userRoll){
      case 'admin':
        setNavigations(_admin_nav);
        break;
      case 'agent':
        setNavigations(_agent_nav);
        break;
      case 'vendor':
        setNavigations(_vendor_nav);
        break;
      default: 
       setNavigations(_vendor_nav);
      break;
    }
  };
  

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible });
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
    
        <img src={CompanyLogo} alt="" style={{ width:'50px', height:'50px' }}/>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          {navigations && <AppSidebarNav items={navigations} />}
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
