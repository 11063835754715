import React, { useContext, useEffect, useState } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCol,
  CFormInput,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { ConfirmationModal, DocsExample, NoRecordFound, TableActionButtons, TableHeaders, TableLoader, Toaster } from 'src/components';
import { PageSearchHeader } from 'src/components';
import { mockBusinessList, userList } from 'src/mockData/userData';
import { useNavigate } from 'react-router-dom';
import { BusinessStatusConstant, PendingVendorTableHeaders, UserTypeConstant } from 'src/utils/Constants';
import { AuthContext } from 'src/context';
import { businessServices } from 'src/services';
import { DownloadExcel, dateFormatting, getPackageNameById } from 'src/utils/CommonFunctions';
import moment from 'moment';

const PendingVendors = () => {
  const { user } = useContext(AuthContext);
  const localPackages = JSON.parse(localStorage.getItem('packages'));  
  const [showLoader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const {toasterMsg, setToasterMsg} = useContext(AuthContext);
  const [pendingBusinessList, setPendingBusinessList] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [business_name, setBusinessName] = useState('');
  const navigate = useNavigate();

  useEffect(()=>{
    getPendingBusinessList();
    return () => {
      setToasterMsg({error:false, message:''});
    };
  },[]);

  useEffect(()=>{
   if(!business_name) getPendingBusinessList();
  },[business_name]);

  const getPendingBusinessList = () => {
    setLoader(true);
    const params = {active_status: [BusinessStatusConstant.pending, BusinessStatusConstant.submitted]};
    if(business_name) params.business_name = business_name;
    businessServices.GetShopListByStatus(params)
    .then((res) => {
        const { data } = res;
        console.log('ressss', data?.data);
        
        if (data?.status) {
          if(user?.role_id === UserTypeConstant.agent){
            const updatedData = data?.data?.filter((item) => parseInt(item?.user?.listed_by) === parseInt(user?.id));
            setPendingBusinessList(updatedData);
          }else{
            setPendingBusinessList(data?.data);
          }
          // setPendingBusinessList(data?.data);
        } else {
          setPendingBusinessList([]);
        }
        setLoader(false);
    })
    .catch((e) => {
        console.log('setPendingBusinessList list err', e);
        let msg = e?.response?.data?.message;
        if (!msg && e?.response?.statusText) {
          msg = e?.response?.statusText;
        }
       setToasterMsg({ message: msg, error: true });
       setLoader(false);
    });
  };

  const publishShopDetails = () => {
    const shopId = selectedShopId;
    const subId = selectedSubId;
    if(!shopId || !subId) return;
    setBtnLoader(true);
    const currentDate = moment();
    // Get the date one year from now
    const dateOneYearFromNow = currentDate.add(1, 'year');
    const data = {active_status: BusinessStatusConstant.published, due_date: dateOneYearFromNow, subscription_id: subId};
    businessServices.EditShopDetails(data, {id: shopId})
    .then((res)=>{
      const data = res?.data;
      console.log('ressss', data);
      if (data?.status) {
        setToasterMsg({ message: 'Shop details has been published', error: false });
        setBtnLoader(false);
        getPendingBusinessList();
      }
    })
    .catch((e)=>{
      console.log('setPendingBusinessList list err', e);
        let msg = e?.response?.data?.message;
        if (!msg && e?.response?.statusText) {
          msg = e?.response?.statusText;
        }
        setToasterMsg({ message: msg, error: true });
        setBtnLoader(true);
    });
  };

  const navigateToView = (shopId) => {
    navigate(`/shopView/${shopId}`);
  };

  const handleDownloadExcel = () => {
    DownloadExcel(pendingBusinessList, 'Pending Vendors')
  };
  const [selectedShopId, setSelectedShopId] = useState();
  const [selectedSubId, setselectedSubId] = useState();
  const handlePublishClick = (shopId, subId) => {
    setSelectedShopId(shopId);
    setselectedSubId(subId);
    setShowConfirmation(true);
  };

  const handleUpdateSearch = (e) => {
    setBusinessName(e.target.value);
  };

  // (vendor name, phone, email, package name, package amount)
  return (
    <CRow>
       <Toaster show={!!toasterMsg.message} colorScheme={toasterMsg.error ? 'danger' : 'success'} message={toasterMsg.message} onToasterClose={() => setToasterMsg('')} autoHide={true}/>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader style={{display:'flex',justifyContent:'space-between'}}>
            <strong>Vendors List</strong>
            {/* <CButton onClick={navigateToAdd}>Add</CButton>             */}
            {/* <CCardLink href='/vendors/add' className='btn btn-primary m-2'>Add</CCardLink> */}
          </CCardHeader>
          <CCardBody>
            <PageSearchHeader handleDownloadExcel={handleDownloadExcel} handleUpdateSearch={handleUpdateSearch} getList={getPendingBusinessList} business_name={business_name}/>
            <div className='table-responsive'>
              <CTable striped hover bordered className="mt-4">
              {showLoader && <TableLoader tblArray={PendingVendorTableHeaders} />}
                {(!showLoader && pendingBusinessList?.length === 0) && <NoRecordFound />}
                {(pendingBusinessList?.length > 0) && <TableHeaders haederArr={PendingVendorTableHeaders} />}
                <CTableBody>
                  {
                    pendingBusinessList?.map((business, index) => {
                      return( 
                      <CTableRow key={business.id}>
                        <CTableHeaderCell scope="row">{index+1}</CTableHeaderCell>
                        <CTableDataCell>{`${business?.user?.first_name} ${business?.user?.last_name}` ?? '--'}</CTableDataCell>
                        <CTableDataCell>{business?.user?.phone_number ?? '--'}</CTableDataCell>
                        <CTableDataCell>{business?.user?.email ?? '--'}</CTableDataCell>
                        <CTableDataCell>{business?.business_name ?? '--'}</CTableDataCell>
                        <CTableDataCell>{business?.city ?? '--'}</CTableDataCell>
                        <CTableDataCell>{getPackageNameById(localPackages, business?.subscriptions[0]?.package_id) ?? '--'}</CTableDataCell>
                        <CTableDataCell>{ business?.subscriptions[0]?.amount ?? '--'}</CTableDataCell>
                        <CTableDataCell>{business?.user?.createdAt ? dateFormatting(business?.user?.createdAt) : '--'}</CTableDataCell>
                        <CTableDataCell>
                            <CButton color='link mb-2 mr-1' onClick={()=>{navigateToView(business.id)}} disabled={business?.active_status === BusinessStatusConstant.pending}
                            title='Click to view shop details'>View</CButton>
                        </CTableDataCell>
                        <CTableDataCell>
                         {user?.role_id === UserTypeConstant.admin && <CButton color='primary mb-2 mr-1' style={{display:'flex'}} onClick={()=>{handlePublishClick(business.id, business?.subscriptions[0]?.id)}} disabled={business?.active_status === BusinessStatusConstant.pending}
                         title='Can only approve if shop details has been submitted.'>Publish
                         {btnLoader && <CSpinner variant="grow" className='ml-1'/>}
                         </CButton>}
                        </CTableDataCell >
                        
                       </CTableRow>);
                    })
                  }
                  
                </CTableBody>
              </CTable>
              </div>
            {/* </DocsExample> */}
          </CCardBody>
        </CCard>
      </CCol>
      <ConfirmationModal 
        open={showConfirmation}
        handleClose={()=> setShowConfirmation(false)}
        handleConfirm={()=> {
          setShowConfirmation(false);
          publishShopDetails();
        }}
        message={'Are you sure you want to publish shop details.'}
      />
    </CRow>
  );
};

export default PendingVendors;
