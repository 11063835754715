import React, { createContext, useEffect, useState } from 'react';
import { AuthContext } from '.';
import PropTypes from 'prop-types'; // Import prop-types
import { mockUsers } from '../mockData/userData';
import { businessServices, userServices } from 'src/services';

const AuthProvider = ( {children} ) => {
  const [toasterMsg, setToasterMsg] = useState({error:false, message:''});
  const [user, setUser] = useState(()=>{
    const localUser = JSON.parse(localStorage.getItem('user'));
    return localUser;
  });
  const [token, setToken] = useState(()=>{
    const localToken = localStorage.getItem('token');
    const expiresAt = localStorage.getItem('expiresAt');
    // const isTokenValid = localToken && expiresAt && Date.now() < parseInt(expiresAt);
    const isTokenValid = true;
    console.log('isValied', isTokenValid);
    return !isTokenValid ? null : localToken;
  });
  const [categories, setCategories] = useState(()=>{
    const locatCategories = JSON.parse(localStorage.getItem('categories'));
    return  locatCategories;
  });
  const [authApiErrMsg, setAuthApiErrMsg] = useState('');

  const login = (userName, password) => {
   setAuthApiErrMsg('');
   const data = {
      userId: userName,
      password: password
    };
    userServices.UserLogin(data)
    .then((res)=>{
      if(res.status){
        const {access_token, expiresIn, user} = res.data;
        const expiresAt = Date.now() + parseInt(expiresIn) * 1000; // Convert seconds to milliseconds
        localStorage.setItem('token', access_token);
        localStorage.setItem('expiresAt', expiresAt);
        localStorage.setItem('user', JSON.stringify(user));
        setToken(access_token);
        setUser(user);
        getPackageDetails();
        // getCategoriesList();
      }      
      console.log('succesfully login', res);
    })
    .catch((e)=>{
       console.log('login err', e);
       let msg = e?.response?.status === 404 || e?.response?.status === 401 ? 'Invalid User Name or Password':e?.response?.data?.message;
       if(!msg && e?.response?.statusText){
         msg = e?.response?.statusText;
       }
       setAuthApiErrMsg(msg);
    });
  };

  const getUserDetails = () => {
    const data = {email: user?.email};
    userServices.GetUsersByEmail(data)
    .then((res)=>{
      if(res.status){
        const { user } = res.data;
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);
      }      
      console.log('succesfully fetched user details', res);
    })
    .catch((e)=>{
       console.log('login err', e);
       let msg = e?.response?.data?.message;
       if(!msg && e?.response?.statusText){
         msg = e?.response?.statusText;
       }
       setAuthApiErrMsg(msg);
    });
  }

  const getPackageDetails = () => {
    businessServices.GetAllPackages()
    .then((res)=>{
      if(res?.data?.status){
        const {data} = res.data;
        console.log('package name ****', data);
        localStorage.setItem('packages', JSON.stringify(data));
      }      
      console.log('succesfully packages', res);
    })
    .catch((e)=>{
       console.log('packages err', e);
       let msg = e?.response?.data?.message;
       if(!msg && e?.response?.statusText){
         msg = e?.response?.statusText;
       }
       setAuthApiErrMsg(msg);
    });
  };

  const getCategoriesList = () => {
    businessServices.GetAllCategories()
    .then((res)=>{
      if(res.status){
        const {data} = res.data;
        setCategories(data);
        localStorage.setItem('categories', JSON.stringify(data));
      }      
      console.log('succesfully categories', res);
    })
    .catch((e)=>{
       console.log('packages err', e);
       let msg = e?.response?.data?.message;
       if(!msg && e?.response?.statusText){
         msg = e?.response?.statusText;
       }
       setAuthApiErrMsg(msg);
    });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('user');
    localStorage.removeItem('packages');
    localStorage.removeItem('categories');
    // Clear token from state
    setToken(null);
    setUser(null);
  };
  
  const AuthContextValue = { user, login, logout, authApiErrMsg, token, toasterMsg, setToasterMsg, getUserDetails, getCategoriesList, categories};

  return (
    <AuthContext.Provider value={AuthContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
