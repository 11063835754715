import React from 'react';
import PropTypes from 'prop-types';
import { CPlaceholder, CTableHeaderCell, CTableRow } from '@coreui/react';

const TableLoaders = (props) => {
    const {tblArray} = props;
    const loader = (
        <CPlaceholder as="p" animation="glow">     
           <CPlaceholder xs={11} />
        </CPlaceholder>
    );

    return(
    <>
      {tblArray.map((item, index)=>{
        return( 
         <CTableRow key={index} className='p-2'>
           <CTableHeaderCell scope="row" colSpan={tblArray.length}>{loader}</CTableHeaderCell>
        </CTableRow>
      );
     })}
    </>
    );
  };

export default TableLoaders;

TableLoaders.propTypes = {
    tblArray: PropTypes.array,
};
