import React, { useEffect, useState } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCol,
  CFormInput,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { DocsExample, TableActionButtons, TableHeaders } from 'src/components';
import { PageSearchHeader } from 'src/components';
import { commisionsList } from 'src/mockData/userData';
import { AgentCommissionHeaders } from 'src/utils/Constants';

const AgentsCommisions = () => {

  const [agents, setAgents] = useState([]);
  
  useEffect(()=>{
    getCommisionList();
  },[]);

  const getCommisionList = () => {
    setAgents(commisionsList);
  };
//   Agent Name, Vendor Name,  package name, package amount, commision amount.
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader style={{display:'flex',justifyContent:'space-between'}}>
            <strong>Agents Commission</strong>
            {/* <CCardLink href='/agents/add' className='btn btn-primary m-2'>Add</CCardLink>            */}
          </CCardHeader>
          <CCardBody>
            <PageSearchHeader />
             <div className='table-responsive'>
              <CTable striped hover bordered className="mt-4">
                <TableHeaders haederArr={AgentCommissionHeaders} />
                <CTableBody>
                  {
                    commisionsList?.map((commision, index) => {
                      return( 
                      <CTableRow key={commision.id}>
                        <CTableHeaderCell scope="row">{commision.id}</CTableHeaderCell>
                        <CTableDataCell>{commision.agentName}</CTableDataCell>
                        <CTableDataCell>{commision.vendorName}</CTableDataCell>
                        <CTableDataCell>{commision.packageName}</CTableDataCell>
                        <CTableDataCell>{commision.packageAmount}</CTableDataCell>
                        <CTableDataCell>{commision.commisionAmount}</CTableDataCell>
                        {/* <CTableDataCell>
                          <TableActionButtons />
                        </CTableDataCell> */}
                       </CTableRow>);
                    })
                  }
                  
                </CTableBody>
              </CTable>
              </div>
            {/* </DocsExample> */}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AgentsCommisions;
