import React, { useContext } from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilGroup,
  cilSitemap,
  cilWindowRestore,
  cilPuzzle,
  cilSpeedometer,
  cibShopify,
  cilHandshake,
  cilDollar,
  cilBank,
  cilUser,
  cilMoney,
  cilMobile
} from '@coreui/icons';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react';
import { AuthContext } from './context';
import { AgentsPageVisiblity, DistributorPageVisiblity, PageName, UserTypeConstant } from './utils/Constants';

// const { user } = useContext(AuthContext);

const showNavItem = (pageName) => {
  return true;
  //  switch(user?.type){
  //   case UserTypeConstant.admin:
  //     return true;
  //   case UserTypeConstant.distributor:
  //     return DistributorPageVisiblity.findIndex(pageName) > -1;
  //    case UserTypeConstant.agent:
  //     return AgentsPageVisiblity.findIndex(pageName) > -1;
  //    case UserTypeConstant.user:
  //     return AgentsPageVisiblity.findIndex(pageName) > -1;
  //     default: false;
  //  }
};


export const _agent_nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavTitle,
    name: 'My Details',
  },
  {
    component: CNavItem,
    name: 'My Profile',
    to: '/profile',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'My Bank Details',
  //   to: '/agents/bankDetails',
  //   icon: <CIcon icon={cilBank} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'My Commissions',
  //   to: '/agents/commissions',
  //   icon: <CIcon icon={cilDollar} customClassName="nav-icon" />,
  // },
  {
    component: CNavTitle,
    name: 'Users',
  },
  {
    component: CNavGroup,
    name: 'Vendors',
    to: '/vendors',
    icon: <CIcon icon={cibShopify} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Vendors',
        to: '/vendors',
      },
      {
        component: CNavItem,
        name: 'Pending Vendors',
        to: '/vendors/pending',
      }
    ],
  },
];

export const _vendor_nav = [
  {
    component: CNavTitle,
    name: 'My Details',
  },
  {
    component: CNavItem,
    name: 'My Profile',
    to: '/profile',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'My Shop Details',
    to: '/shops',
    icon: <CIcon icon={cibShopify} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'My Subscriptions',
    to: '/subscriptions',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },
];

export const _admin_nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavTitle,
    name: 'Users',
  },
  {
    component: CNavItem,
    name: 'Agents',
    to: '/agents',
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Agent Commissions',
  //   to: '/agents/commissions',
  //   icon: <CIcon icon={cilDollar} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Distributors',
  //   to: '/distributors',
  //   icon: <CIcon icon={cilSitemap} customClassName="nav-icon" />,
  // },
  {
    component: CNavGroup,
    name: 'Vendors',
    to: '/vendors',
    icon: <CIcon icon={cibShopify} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Vendors',
        to: '/vendors',
      },
      {
        component: CNavItem,
        name: 'Pending Vendors',
        to: '/vendors/pending',
      }
    ],
  },
  {
    component: CNavTitle,
    name: 'Settings',
  },
  // {
  //   component: CNavItem,
  //   name: 'Mobile App',
  //   to: '/mobileApp',
  //   icon: <CIcon icon={cilMobile} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Reports',
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Users',
  //   to: '/base',
  //   icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Vendors Report',
  //       to: '/base/accordion',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Distributor Report',
  //       to: '/base/breadcrumbs',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Agents Report',
  //       to: '/base/cards',
  //     },
  //   ],
  // },
];

// export default _nav;
