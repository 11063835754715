import moment from "moment/moment";
import * as XLSX from 'xlsx';

export const dateFormatting = (timestamp) => {
   const formattedDate = moment(timestamp).format('DD/MM/YYYY');
   return formattedDate;
};

export const handleWheel = (e) => {
   // Prevent changing the number input value when scrolling with the mouse wheel
   if (document.activeElement === e.target) {
       e.preventDefault();
   }
};

export const getCategoryName = (categories, shopDetailsInfo) => {
   const findCategory = categories?.find((cat) => cat.id === shopDetailsInfo?.category_id);
   return findCategory?.name;
};

export const getPackageNameById = (packages, packageId) => {
   const findPackage = packages?.find((pck) => pck.id === parseInt(packageId));
   return findPackage?.package_name;
};

export const DownloadExcel = (data, name) => {
   const ws = XLSX.utils.json_to_sheet(data);
   const wb = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, name);
   XLSX.writeFile(wb, `${name}.xlsx`);
 };
 