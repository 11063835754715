import React, { useContext, useEffect, useState } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCol,
  CFormFloating,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { DocsExample, NoRecordFound, TableActionButtons } from 'src/components';
import { PageSearchHeader } from 'src/components';
import { mockBusinessList, userList } from 'src/mockData/userData';
import { useNavigate } from 'react-router-dom';
import { UrlConstants } from 'src/utils/UrlConstants';
import Select from 'react-dropdown-select';
import DatePicker from "react-datepicker";
import { AuthContext } from 'src/context';
import PropTypes from 'prop-types'; // Import prop-types


const ShopContactDetails = (props) => {
  const { shopContacts } =  props;
  const {getCategoriesList} = useContext(AuthContext);
  const [businessList, setBusinessList] = useState([]);
  const [shopDetailsInfo, setShopDetailsInfo] = useState({});
  const navigate = useNavigate();
  useEffect(()=>{
    getBusinessList();
    getCategoriesList();
  },[]);

  const getBusinessList = () => {
    const businesses = mockBusinessList;
    setBusinessList(businesses);
  };

  const navigateToAdd = () => {
    navigate(UrlConstants.routeUrls.addShop);
  };

  const renderShopDetails = () => {
    return(
      <div>
       <div className="shop-details-wrapper">
          <div className="row">
            {
              shopContacts?.map((item)=>{
                return(
                  <div className="col-md-3" key={`shopCont_${item.id}`}>
                  <div className="shop-details-text">
                    <label>Mobile Number</label>
                    <span>{item.phone_number}</span>
                  </div>
                 </div>
                )
              })
            }
            
          </div>
        </div>
      </div>
    );
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardBody>
            {(!shopContacts || shopContacts.length === 0) && <NoRecordFound message='Please add your additional contacts...' />}
            {renderShopDetails()}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default ShopContactDetails;

ShopContactDetails.propTypes = {
  shopContacts: PropTypes.any
};
