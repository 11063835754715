import React, { useContext, useEffect, useState } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCol,
  CFormFloating,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { DocsExample, NoRecordFound, TableActionButtons } from 'src/components';
import { PageSearchHeader } from 'src/components';
import { mockBusinessList, mockShopDetails, userList } from 'src/mockData/userData';
import { useNavigate } from 'react-router-dom';
import { UrlConstants } from 'src/utils/UrlConstants';
import Select from 'react-dropdown-select';
import DatePicker from "react-datepicker";
import { AuthContext } from 'src/context';
import Logo from '../../../../assets/images/BB-Logo.jpg';
import OfferIcon from "../../../../assets/images/offer-icon.jpeg";
import PropTypes from 'prop-types'; // Import prop-types
import { daysArray } from 'src/utils/Constants';

const BasicShopDetails = (props) => {
  const { shopDetailsInfo, categoryName } = props;
  const {categories} = useContext(AuthContext);
  console.log('shopDetailsInfo*****', shopDetailsInfo)
  const navigate = useNavigate();
  const navigateToAdd = () => {
    navigate(UrlConstants.routeUrls.addShop);
  };

  const getCloseDay = () => {
    const closeDay = daysArray?.find((item) => item?.value === parseInt(shopDetailsInfo?.close_day));
    console.log('close day***', closeDay);
    return closeDay?.label;
  }

  const renderShopDetails = () => {
    return(
      <div>
        <div className="shop-details-wrapper">
          <div className="row">
            <div className="col-md-6">
              <div className="shop-details-text">
                <label>Category Name</label>
                <span>{categoryName ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="shop-details-text">
                <label>Shop Name</label>
                <span>{shopDetailsInfo?.business_name ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="shop-details-text">
                <label>Discription</label>
                <span>{shopDetailsInfo?.business_description ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                <label>Company Logo</label>
                <span><img src={shopDetailsInfo?.logo_url} alt="Company Logo" className="company-logo-shop"/></span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                 <label>Opening Time</label>
                <span>{shopDetailsInfo?.opening_time ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                 <label>Closing Time</label>
                <span>{shopDetailsInfo?.closing_time ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                <label>Shop Close Day</label>
                <span>{getCloseDay() ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="shop-details-text">
                 <label>Address</label>
                <span>{shopDetailsInfo?.address ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="shop-details-text">
                 <label>Location Link</label>
                <span><a href={shopDetailsInfo?.location_url} target='_blanks'>{shopDetailsInfo?.location_url ?? '--'}</a></span>
              </div>
            </div>

            <div className="col-md-3">
              <div className="shop-details-text">
                 <label>State</label>
                <span>{shopDetailsInfo?.state ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                 <label>District</label>
                <span>{shopDetailsInfo?.district ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                 <label>Taluka</label>
                <span>{shopDetailsInfo?.tehsil ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                 <label>City</label>
                <span>{shopDetailsInfo?.city ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                <label>Pincode</label>
                <span>{shopDetailsInfo?.pin_code ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                <label>GST No</label>
                <span>{shopDetailsInfo?.gst_number ?? '--'}</span>
              </div>
            </div>

            <div className="col-md-3">
              <div className="shop-details-text">
                <label>Shop Catlog URL</label>
                <span><a href={shopDetailsInfo?.catelog_url ?? '#'} target='_blanks'>{shopDetailsInfo?.catelog_url ? 'Download Shop Catlog' : '--'}</a></span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                <label>Offer Icon</label>
                <span><img src={shopDetailsInfo?.offer_icon_url} alt="Offer Icon"/></span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                <label>Whatsapp Number</label>
                <span>{shopDetailsInfo?.wp_url ?? '--'}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                 <label>Facebook Link</label>
                 <span><a href={shopDetailsInfo?.fb_url} target='_blanks'>{shopDetailsInfo?.fb_url ?? '--'}</a></span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                 <label>Instagram Link</label>
                <span><a href={shopDetailsInfo?.insta_url} target='_blanks'>{shopDetailsInfo?.insta_url ?? '--'}</a></span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="shop-details-text">
                 <label>Website Link</label>
                <span>
                    {shopDetailsInfo?.website_url ? <a href={shopDetailsInfo?.website_url}  target='_blank' rel='noreferrer'>{shopDetailsInfo?.website_url}</a> : '--' }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardBody>
            {/* <NoRecordFound /> */}
            {renderShopDetails()}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default BasicShopDetails;

BasicShopDetails.propTypes = {
  shopDetailsInfo: PropTypes.any,
  categoryName: PropTypes.any
};
