import React from 'react';
import PropTypes from 'prop-types';

const NoRecordFound = (props) => {
  const defaultMessage = 'No Record Found';
  const { message = defaultMessage } = props;

  return(
    <div className='no-record-found-wrapper'>
      <h3>{message}</h3>
    </div>
  );
};

export default NoRecordFound;

NoRecordFound.propTypes = {
    message: PropTypes.string,
};
