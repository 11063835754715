import { CToast, CToastBody, CToastClose } from '@coreui/react';
import React from 'react';
import PropTypes from 'prop-types';

const Toaster = ({
    show = false,
    autoHide = false,
    colorScheme = 'primary',
    message = '',
    onToasterClose
}) => {

    return (
        <CToast autohide={autoHide} visible={show} color={colorScheme} className="text-white align-items-center" style={{position: 'absolute', right:'5%', top:'5%', zIndex:99999}} onClose={onToasterClose}>
            <div className="d-flex">
                <CToastBody>{message}</CToastBody>
                <CToastClose className="me-2 m-auto" white />
            </div>
        </CToast>
    );
};

export default Toaster;

Toaster.propTypes = {
    show: PropTypes.boolean,
    autoHide: PropTypes.boolean,
    colorScheme: PropTypes.string,
    message: PropTypes.string,
    onToasterClose: PropTypes.func
};
