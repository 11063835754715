import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CContainer, CSpinner } from '@coreui/react';

// routes config
import routes from '../routes';
import { AuthNavigations, MainNavigations } from '.';

const AppContent = () => {
  const isAuthenticated = false;
  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        {/* <AuthNavigations /> */}
        <MainNavigations />
        {/* {isAuthenticated ? <MainNavigations/> : <AuthNavigations/>} */}
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
