import { api } from './axiosConfig';
import { UrlConstants } from 'src/utils/UrlConstants';
const {apiUrls} = UrlConstants;

// const token = localStorage.getItem('token');

// Set the token in the request headers
// api.defaults.headers['x-access-token'] = token;
// api.defaults.headers.common['x-access-token'] = token;

export const UserLogin = async(data) => {
    return await api.post(apiUrls.login, data);
};

export const UserSignup = async(data) => {
    return await api.post(apiUrls.signup, data);
};

export const GetUsersByRole = async(data) => {
    return await api.get(apiUrls.userByRole, {params: data});
};

export const AddUserBankDetails = async(data) => {
    return await api.post(apiUrls.addBankDetails, data);
};

export const GetUserBankDetails = async(data) => {
    return await api.get(apiUrls.getBankDetails, {params: data});
};

export const UpdateUser = async(data, params) => {
    return await api.patch(apiUrls.editUser, data, {params});
};

export const GetUsersByEmail = async(data) => {
    console.log('apiUrls.getUserByEmail', apiUrls.getUserByEmail)
    return await api.get(apiUrls.getUserByEmail, {params: data});
};
