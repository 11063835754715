import { CCol, CRow, CCard, CCardBody, CCardHeader, CCardFooter, CButton, CForm } from '@coreui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserBasicInfo, BankDetails, Toaster } from 'src/components';
import { AuthContext } from 'src/context';
import { userServices } from 'src/services';
import { AgentSteps, UserTypeConstant } from 'src/utils/Constants';
import { UrlConstants } from 'src/utils/UrlConstants';

const AddBankDetails = () => {
    const location = useLocation();
    const bankData = location?.state?.bankingDetails;
    const { user, setToasterMsg, toasterMsg } = useContext(AuthContext);
    const [bankingDetails, setBankingDetails] = useState({});
    const [validated, setValidated] = useState(false);
    const initialInvalidFields = {
        bankName: false,
        accountNumber: false,
        ifscCode: false
    };
    const [invalidFields, setInvalidFields] = useState(initialInvalidFields);
    const navigate = useNavigate();

    useEffect(()=>{
        bankData && setBankingDetails({...bankData, address_proof_url: '', pan_card_url: '', cancel_check_url: ''});
    },[bankData]);

    const updateBankDetails = (e) => {
        const { name, value } = e.target;
        setBankingDetails({ ...bankingDetails, [name]: value });
    };

    const isValidate = () => {
        // setInvalidFields(initialInvalidFields);
        // if(userDetails.account_number.length < 10 || userDetails.account_number.length > 10) {
        //     setInvalidFields({ ...invalidFields, accountNumber: true });
        //     return false;
        // }
        return true;
    };

    const handleFormSubmit = (event) => {
        setToasterMsg({ message: '', error: false });
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            if (isValidate()) {
                saveBankDetails();
            }else {
                setValidated(false);
                return;
            }
        }
        setValidated(true);
    };

    const saveBankDetails = () => {
        const data = bankingDetails;
        const bankData = {...data, user_id: user?.id};
        console.log('bank data**', bankData);
        userServices.AddUserBankDetails(bankData)
        .then((res) => {
            const { data } = res;
            if (data.status) {
                setToasterMsg({ message: 'Bank details added successfully!', error: false });
                navigate(UrlConstants.routeUrls.agentBankDetails);
            } else {
                setToasterMsg({ message: data.message, error: true });
            }
        })
        .catch((e) => {
            console.log('login err', e);
            let msg = e?.response?.data?.message;
            if (!msg && e?.response?.statusText) {
              msg = e?.response?.statusText;
            }
          setToasterMsg({ message: msg, error: true });
        });
    };

    return (
        <CRow>
            <Toaster autoHide={true} show={!!toasterMsg.message} colorScheme={toasterMsg.error ? 'danger' : 'success'} message={toasterMsg.message} onToasterClose={() => setToasterMsg('')}/>
            <CCol xs={12}>
                <CCard className="mb-4">
                    <CCardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong>Add Bank Details</strong>
                        <strong>{AgentSteps.bankingDetails.name}</strong>
                    </CCardHeader>
                    <CForm
                        className="row g-3 needs-validation"
                        noValidate
                        validated={validated}
                        onSubmit={handleFormSubmit}
                    >
                        <CCardBody>
                            <BankDetails updateBankDetails={updateBankDetails} bankingDetails={bankingDetails} />
                            {/* <UserBasicInfo userDetails={userDetails} updateUserDetails={updateUserDetails} userType={UserTypeConstant.agent} invalidFields={invalidFields} /> */}
                            {/* {step.step === 2 && <BankDetails bankingDetails={bankingDetails} updateBankDetails={updateBankDetails}/>} */}

                        </CCardBody>
                        <CCardFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CButton type='submit'>Submit</CButton>
                        </CCardFooter>
                    </CForm>
                </CCard>
            </CCol>
        </CRow>
    );

};

export default AddBankDetails;
