import React, { useContext, useEffect, useState } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCol,
  CFormInput,
  CPlaceholder,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { DocsExample, NoRecordFound, TableActionButtons, TableHeaders, TableLoader, Toaster } from 'src/components';
import { PageSearchHeader } from 'src/components';
import { userList } from 'src/mockData/userData';
import { AuthContext } from 'src/context';
import { userServices } from 'src/services';
import { AgentTableHeaders, UserTypeConstant } from 'src/utils/Constants';
import { useNavigate } from 'react-router-dom';
import { UrlConstants } from 'src/utils/UrlConstants';
import { DownloadExcel, dateFormatting } from 'src/utils/CommonFunctions';

const Agents = () => {
  const [agents, setAgents] = useState([]);
  const [showLoader, setLoader] = useState(false);
  const {toasterMsg, setToasterMsg} = useContext(AuthContext);
  const navigate = useNavigate();
  
  useEffect(()=>{
    getAgentList();
    return () => {
      setToasterMsg({error:false, message:''});
    };
  },[]);

  const getAgentList = () => {
    setLoader(true);
    userServices.GetUsersByRole({role_id: UserTypeConstant.agent})
    .then((res) => {
        const { data } = res;
        console.log('ressss', res);
        if (data?.status) {
            setAgents(data?.user);
        } else {
          setAgents([]);
        }
        setLoader(false);
    })
    .catch((e) => {
        console.log('login err', e);
        let msg = e?.response?.data?.message;
        if (!msg && e?.response?.statusText) {
          msg = e?.response?.statusText;
        }
       setToasterMsg({ message: msg, error: true });
       setLoader(false);
    });
  };

  const navigateToAdd = () => {
    navigate(UrlConstants.routeUrls.addAgent);
  };

  const navigateToView = (agentId, agentEmail) => {
    navigate(`/agent/${agentId}/${agentEmail}`);
  };

  const RenderTableData = () => {
    return(
      <>
       {
        agents?.map((agent, index) => {
          const userName = `${agent?.first_name} ${agent?.last_name}`;
          return( 
          <CTableRow key={agent.id}>
            <CTableHeaderCell scope="row">{index+1}</CTableHeaderCell>
            <CTableDataCell>{userName}</CTableDataCell>
            <CTableDataCell>{agent.phone_number}</CTableDataCell>
            <CTableDataCell>{agent.email}</CTableDataCell>
            <CTableDataCell>{dateFormatting(agent.createdAt)}</CTableDataCell>
            <CTableDataCell>
              <CButton color='link mb-2 mr-1' onClick={()=>{navigateToView(agent.id, agent.email)}}  
                title='Click to view shop details'>View</CButton>
            </CTableDataCell>
            <CTableDataCell>
              <TableActionButtons />
            </CTableDataCell>
            </CTableRow>);
        })
       }
      </>
    );
  };
 
  const handleDownloadExcel = () => {
    DownloadExcel(agents, 'Agents')
  };

  return (
    <CRow>
       <Toaster show={!!toasterMsg.message} colorScheme={toasterMsg.error ? 'danger' : 'success'} message={toasterMsg.message} onToasterClose={() => setToasterMsg('')} autoHide={true}/>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader style={{display:'flex',justifyContent:'space-between'}}>
            <strong>Agents List</strong>
            <CButton className='btn btn-primary m-2' onClick={navigateToAdd}>Add</CButton>     
          </CCardHeader>
          <CCardBody>
            <PageSearchHeader handleDownloadExcel={handleDownloadExcel} />
             <div className='table-responsive'>
              <CTable striped hover bordered className="mt-4">
                {showLoader && <TableLoader tblArray={AgentTableHeaders} />}
                {(!showLoader && agents?.length === 0) && <NoRecordFound />}

                {agents?.length>0 && <><TableHeaders haederArr={AgentTableHeaders} />
                <CTableBody>
                  <RenderTableData />
                </CTableBody></>}
              </CTable>
              </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Agents;
