import React from 'react';
import { PendigVendors } from './views/pages';

const Dashboard = React.lazy(() => import('./views/pages/dashboard/Dashboard'));

// Base
const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'));
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'));
const Cards = React.lazy(() => import('./views/base/cards/Cards'));
const Agents = React.lazy(() => import('./views/pages/agents/Agents'));
const AddAgents = React.lazy(() => import('./views/pages/agents/AddAgents'));
const AgentsCommisions = React.lazy(() => import('./views/pages/agents/AgentsCommisions'));
const BankDetailViews = React.lazy(() => import('./views/pages/agents/BankDetailViews'));
const AddBankDetails = React.lazy(() => import('./views/pages/agents/AddBankDetails'));
const Vendors = React.lazy(() => import('./views/pages/vendors/Vendors'));
const AddVendor = React.lazy(() => import('./views/pages/vendors/AddVendor'));
const Distributors = React.lazy(() => import('./views/pages/distributors/Distributors'));
const MyShopDetails = React.lazy(() => import('./views/pages/shops/shopDetails/MyShopDetails'));
const AddShopDetails = React.lazy(() => import('./views/pages/shops/AddShopDetails'));
const MyProfile = React.lazy(() => import('./views/pages/userProfile/MyProfile'));
const EditProfile = React.lazy(() => import('./views/pages/userProfile/EditProfile'));
const UserSubscriptions = React.lazy(() => import('./views/pages/subscriptions/UserSubscriptions'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const Tables = React.lazy(() => import('./views/base/tables/Tables'));
const ShopDetailsAdminView = React.lazy(() => import('./views/pages/shops/shopDetails/ShopDetailsAdminView'));
const AgentDetailsAdminView = React.lazy(() => import('./views/pages/agents/AgentDetailsAdminView'));


export const AgentRoutes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/profile', name: 'User Profile', element: MyProfile },
  { path: '/profile/edit', name: 'Edit Profile', element: EditProfile },
  { path: '/agents/bankDetails', name: 'Bank Details', element: BankDetailViews },
  { path: '/agents/bankDetails/add', name: 'Bank Details', element: AddBankDetails },
  { path: '/vendors', name: 'Vendors', element: Vendors },
  { path: '/vendors/pending', name: 'Pending Vendor', element: PendigVendors },
  { path: '/vendors/add', name: 'Add Vendor', element: AddVendor },
  { path: '/shops/add', name: 'Add Shop Details', element: AddShopDetails },
  { path: '/notfound', name: 'Not found', element: Page404 },
  { path: '/agents/commissions', name: 'Agents Commissions', element: AgentsCommisions },
  { path: '/shopView/:shopId', name: 'Shop', element: ShopDetailsAdminView },
];

export const VendorRoutes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/profile', name: 'User Profile', element: MyProfile },
  { path: '/profile/edit', name: 'Edit Profile', element: EditProfile },
  { path: '/shops', name: 'Shop', element: MyShopDetails },
  { path: '/shops/add', name: 'Add Shop Details', element: AddShopDetails },
  { path: '/subscriptions', name: 'Subscriptions', element: UserSubscriptions },
  { path: '/notfound', name: 'Not found', element: Page404 },
];

export const MainRoutes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/profile', name: 'User Profile', element: MyProfile },
  { path: '/profile/edit', name: 'Edit Profile', element: EditProfile },
  { path: '/agents', name: 'Agents', element: Agents },
  { path: '/agents/add', name: 'Add Agents', element: AddAgents },
  { path: '/agents/commissions', name: 'Agents Commissions', element: AgentsCommisions },
  { path: '/agents/bankDetails', name: 'Bank Details', element: BankDetailViews },
  { path: '/vendors', name: 'Vendors', element: Vendors },
  { path: '/vendors/pending', name: 'Pending Vendor', element: PendigVendors },
  { path: '/vendors/add', name: 'Add Vendor', element: AddVendor },
  { path: '/shops', name: 'Shop', element: MyShopDetails },
  { path: '/subscriptions', name: 'Subscriptions', element: UserSubscriptions },
  { path: '/shops/add', name: 'Add Shop Details', element: AddShopDetails },
  { path: '/distributors', name: 'Business', element: Distributors },
  { path: '/base/tables', name: 'Tables', element: Tables },
  { path: '/base', name: 'Base', element: Cards, exact: true },
  { path: '/base/accordion', name: 'Accordion', element: Accordion },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', element: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', element: Cards },
  { path: '/notfound', name: 'Not found', element: Page404 },
  { path: '/shopView/:shopId', name: 'Shop', element: ShopDetailsAdminView },
  { path: '/agent/:agentId/:agentEmail', name: 'Agent', element: AgentDetailsAdminView },
  { path: '/mobileApp', name: 'mobileApp', element: Agents, exact: true },

];
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));

export const AuthRoutes = [
  { path: '/', exact: true, name: 'Login' },
  { path: '/login', name: 'Login', element: Login },
  { path: '/register', name: 'Register', element: Register },
  { path: '/notfound', name: 'Not found', element: Page404 },
  { path: '/error', name: 'error', element: Page500 },
];

// export default routes;
