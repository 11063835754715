import { CForm, CFormInput, CFormLabel, CInputGroup,CFormFloating, CContainer } from "@coreui/react";
import React from "react";
import PropTypes from 'prop-types'; // Import prop-types
import msScanner from 'src/assets/images/scanner/msScanner.jpeg';

const UserProfile = (props) => {
    const { userDetails } = props;

    {/* field
        firstName
        lastName
        email(loginID)
        password
        confirm password
        adharNo
        listedBy(userID name) 
        // roleId - auto entered(don't show)
    */}

    return(

        <div className="show-info-wrapper">
          <div className="row">
            <div className="col-md-6">
              <div className="show-info-text">
                 <label>First Name</label>
                <span>{userDetails?.first_name}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="show-info-text">
                 <label>Last Name</label>
                <span>{userDetails?.last_name}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="show-info-text">
                <label>Email Address</label>
                <span>{userDetails?.email}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="show-info-text">
                <label>Mobile Number</label>
                <span>{userDetails?.phone_number}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="show-info-text">
                <label>Aadhaar Number</label>
                <span>{userDetails?.aadhar_number ?? '--'}</span>
              </div>
            </div>
          </div>
        </div>
    );
};

export default UserProfile;

UserProfile.propTypes = {
    userDetails: PropTypes.any,
};
