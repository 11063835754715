import React, { useContext, useEffect, useState } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCol,
  CFormInput,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { DocsExample, NoRecordFound, TableActionButtons, TableHeaders, TableLoader, Toaster } from 'src/components';
import { PageSearchHeader } from 'src/components';
import { mockBusinessList, userList } from 'src/mockData/userData';
import { useNavigate } from 'react-router-dom';
import { UrlConstants } from 'src/utils/UrlConstants';
import { BusinessStatusConstant, UserTypeConstant, VendorTableHeaders } from 'src/utils/Constants';
import { AuthContext } from 'src/context';
import { businessServices } from 'src/services';
import { DownloadExcel, dateFormatting, getPackageNameById } from 'src/utils/CommonFunctions';

const Vendors = () => {
  const localPackages = JSON.parse(localStorage.getItem('packages'));
  const { user } = useContext(AuthContext);
  const [showLoader, setLoader] = useState(false);
  const {toasterMsg, setToasterMsg} = useContext(AuthContext);
  const [businessList, setBusinessList] = useState([]);
  const [business_name, setBusinessName] = useState('');
  const navigate = useNavigate();
  
  useEffect(()=>{
    getBusinessList();
    return () => {
      setToasterMsg({error:false, message:''});
    };
  },[]);

  useEffect(()=>{
    if(!business_name) getBusinessList();
   },[business_name]);

   const handleUpdateSearch = (e) => {
    setBusinessName(e.target.value);
  };

  const getBusinessList = () => {
    setLoader(true);
    const params = {active_status: BusinessStatusConstant.published};
    if(business_name) params.business_name = business_name;
    businessServices.GetShopListByStatus(params)
    .then((res) => {
        const { data } = res;
        console.log('ressss', data?.data);
        if (data?.status) {
          if(user?.role_id === UserTypeConstant.agent){
            const updatedData = data?.data?.filter((item) => parseInt(item?.user?.listed_by) === parseInt(user?.id));
            setBusinessList(updatedData);
          }else{
            setBusinessList(data?.data);
          }
        } else {
          setBusinessList([]);
        }
        setLoader(false);
    })
    .catch((e) => {
        console.log('business list err', e);
        let msg = e?.response?.data?.message;
        if (!msg && e?.response?.statusText) {
          msg = e?.response?.statusText;
        }
       setToasterMsg({ message: msg, error: true });
       setLoader(false);
    });
  };

  const navigateToAdd = () => {
    navigate(UrlConstants.routeUrls.addVendor);
  };
  const navigateToView = (shopId) => {
    navigate(`/shopView/${shopId}`);
  };

  const handleDownloadExcel = () => {
    DownloadExcel(businessList, 'Vendors')
  };

  // vendor name, phone, email, package name, package amount, due date
  return (
    <CRow>
      <Toaster show={!!toasterMsg.message} colorScheme={toasterMsg.error ? 'danger' : 'success'} message={toasterMsg.message} onToasterClose={() => setToasterMsg('')} autoHide={true}/>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader style={{display:'flex',justifyContent:'space-between'}}>
            <strong>Vendors List</strong>
            <CButton className='btn btn-primary m-2' onClick={navigateToAdd}>Add</CButton>
            {/* <CButton onClick={navigateToAdd}>Add</CButton>             */}
          </CCardHeader>
          <CCardBody>
            <PageSearchHeader handleDownloadExcel={handleDownloadExcel} handleUpdateSearch={handleUpdateSearch} getList={getBusinessList} business_name={business_name}/>
            <div className='table-responsive'>
              <CTable striped hover bordered className="mt-4">
                {showLoader && <TableLoader tblArray={VendorTableHeaders} />}
                {(!showLoader && businessList?.length === 0) && <NoRecordFound />}
                {(businessList?.length > 0) && <TableHeaders haederArr={VendorTableHeaders} />}
                <CTableBody>
                  {
                    businessList?.map((business, index) => {
                      return( 
                      <CTableRow key={business?.id}>
                        <CTableHeaderCell scope="row">{index+1}</CTableHeaderCell>
                        <CTableDataCell>{`${business?.user?.first_name} ${business?.user?.last_name}` ?? '--'}</CTableDataCell>
                        <CTableDataCell>{business?.user?.phone_number}</CTableDataCell>
                        <CTableDataCell>{business?.user?.email}</CTableDataCell>
                        <CTableDataCell>{business?.business_name}</CTableDataCell>
                        <CTableDataCell>{business?.city}</CTableDataCell>
                        <CTableDataCell>{getPackageNameById(localPackages, business?.subscriptions[0]?.package_id) ?? '--'}</CTableDataCell>
                        <CTableDataCell>{business?.subscriptions[0]?.amount ?? '--'}</CTableDataCell>
                        <CTableDataCell>{business?.subscriptions[0]?.createdAt ? dateFormatting(business?.subscriptions[0]?.createdAt) : '--'}</CTableDataCell>
                        <CTableDataCell>{business?.subscriptions[0]?.due_date ? dateFormatting(business?.subscriptions[0]?.due_date) : '--'}</CTableDataCell>
                        <CTableDataCell>
                            <CButton color='link mb-2 mr-1' onClick={()=>{navigateToView(business.id)}} disabled={business?.active_status === BusinessStatusConstant.pending}
                            title='Click to view shop details'>View</CButton>
                        </CTableDataCell>
                        <CTableDataCell>
                        {user?.role_id === UserTypeConstant.admin && <TableActionButtons />}
                        </CTableDataCell>
                       </CTableRow>);
                    })
                  }
                  
                </CTableBody>
              </CTable>
              </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Vendors;
