import React, { useContext, useEffect, useState } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCol,
  CFormFloating,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { DocsExample, NoRecordFound, TableActionButtons } from 'src/components';
import { PageSearchHeader } from 'src/components';
import { mockBusinessList, userList } from 'src/mockData/userData';
import { useNavigate } from 'react-router-dom';
import { UrlConstants } from 'src/utils/UrlConstants';
import Select from 'react-dropdown-select';
import DatePicker from "react-datepicker";
import { AuthContext } from 'src/context';
// import ShopImages from '../../../../assets/images/listings_details-img1categ.jpg';
import PropTypes from 'prop-types'; // Import prop-types

const ShopImagesDetails = (props) => {
  const { shopImages } =  props;
  console.log('shop images', shopImages);
  const renderShopDetails = () => {
    return(
      <div>
       <div className="shop-details-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="shop-details-text">
                <label>Shop Images</label>
                <div className="shop-details-img-wrapper">
                 {shopImages?.map((img)=>{
                    return <img src={img.image_url} alt="Company Logo" className="shop-images-details" key={`shopImg_${img.id}`}/>
                 })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardBody>
            {(!shopImages || shopImages.length === 0) && <NoRecordFound message='Please add your shop images...' />}
            {(shopImages && shopImages.length > 0) && renderShopDetails()}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default ShopImagesDetails;

ShopImagesDetails.propTypes = {
  shopImages: PropTypes.any
};
