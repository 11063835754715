import React from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
// routes config
import { AuthRoutes } from '../routes';
const AuthNavigations = () => {

    

    return(
        <Routes>
          {AuthRoutes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
    );
};

export default AuthNavigations;
