import React, { useContext, useEffect, useState } from 'react';
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow
} from '@coreui/react';
import { BankView, NoRecordFound, Toaster, UserProfile } from 'src/components';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from 'src/context';
import { userServices } from 'src/services';


const AgentDetailsAdminView = () => {
  const { agentId, agentEmail } = useParams();
  const { toasterMsg, setToasterMsg } = useContext(AuthContext);
  const [showLoader, setLoader] = useState(false);
  const [agentDetails, setAgentDetails] = useState();
  const [agentBankDetails, setAgentBankDetails] = useState();

  const navigate = useNavigate();

  useEffect(()=>{
   if(agentId) {
    getAgentDetails();
    getAgentBankDetails();
   }
  },[agentId]);
  
  const getAgentDetails = () => {
    const data = {email: agentEmail};
    userServices.GetUsersByEmail(data)
    .then((res)=>{
      if(res.status){
        const { user } = res.data;
        setAgentDetails(user);
      }      
      console.log('succesfully fetched agent details', res);
    })
    .catch((e)=>{
       console.log('login err', e);
       let msg = e?.response?.data?.message;
       if(!msg && e?.response?.statusText){
         msg = e?.response?.statusText;
       }
       setToasterMsg({ message: msg, error: true });
    });
  };

  const getAgentBankDetails = () => {
    setLoader(true);
    userServices.GetUserBankDetails({user_id: agentId})
    .then((res) => {
        const { data } = res;
        console.log('data?.bankDetails', data?.bankDetails);
        if (data?.status) {
            setAgentBankDetails(data?.bankDetails);
        } else {
            setAgentBankDetails();
        }
        setLoader(false);
    })
    .catch((e) => {
        console.log('login err', e);
        let msg = e?.response?.data?.message;
        if (!msg && e?.response?.statusText) {
          msg = e?.response?.statusText;
        }
       setToasterMsg({ message: msg, error: true });
       setLoader(false);
    });
  };


  const renderShopAccordians = () => {
    return(
      <CAccordion activeItemKey={1}>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader>Basic Detalis</CAccordionHeader>
                <CAccordionBody>
                  <UserProfile userDetails={agentDetails} />
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={2}>
                <CAccordionHeader>Bank Details</CAccordionHeader>
                <CAccordionBody>
                    <BankView bankingDetails={agentBankDetails}/>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
    );
  };

  return (
    <CRow>
      <Toaster show={!!toasterMsg.message} colorScheme={toasterMsg.error ? 'danger' : 'success'} message={toasterMsg.message} onToasterClose={() => setToasterMsg('')} autoHide={true}/>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader style={{display:'flex',justifyContent:'space-between'}}>
            <strong>Agent Details</strong>
          </CCardHeader>
          <CCardBody>
            {(!agentDetails && !agentBankDetails) &&<NoRecordFound/>}
            {(agentDetails || agentBankDetails) && renderShopAccordians()}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AgentDetailsAdminView;
