import React from 'react';
import PropTypes from 'prop-types';
import { CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';

const TableHeaders = (props) => {
  const { haederArr = [] } = props;

  return(
    <CTableHead>
      <CTableRow>
        {haederArr?.map((item, index)=>{
         return <CTableHeaderCell scope="col" key={`tblAgent_${index}`}>{item}</CTableHeaderCell>;
         })
         }
      </CTableRow>
    </CTableHead>
  );
};

export default TableHeaders;

TableHeaders.propTypes = {
    haederArr: PropTypes.array,
};
