import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './scss/style.scss';
import { AuthProvider } from './context';
import Navigations from './navigations/Navigations';

const App = () => {
  return (
    <BrowserRouter>
    <AuthProvider>
        <Navigations />
    </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
