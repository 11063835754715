import { CSpinner } from '@coreui/react';
import React from 'react';

const PageLoader = () => {
    return(
        <div className="overlay-container">
      <div className="overlay">
        <div className='d-flex justify-content-center h-100'>
            <CSpinner color="primary" variant="grow" />
            <CSpinner color="secondary" variant="grow" />
            <CSpinner color="success" variant="grow" />
            <CSpinner color="danger" variant="grow" />
            <CSpinner color="warning" variant="grow" />
            <CSpinner color="info" variant="grow" />
            <CSpinner color="light" variant="grow" />
            <CSpinner color="dark" variant="grow" />
        </div>
        </div>
        </div>
    )
};

export default PageLoader;
