import React, { useContext, useEffect, useState } from 'react';
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCol,
  CFormFloating,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { DocsExample, NoRecordFound, TableActionButtons, Toaster } from 'src/components';
import { PageSearchHeader } from 'src/components';
import { mockBusinessList, userList } from 'src/mockData/userData';
import { useNavigate, useParams } from 'react-router-dom';
import { UrlConstants } from 'src/utils/UrlConstants';
import Select from 'react-dropdown-select';
import DatePicker from "react-datepicker";
import { AuthContext } from 'src/context';
import BasicShopDetails from './BasicShopDetails';
import ShopContactDetails from './ShopContactDetails';
import ShopImagesDetails from './ShopImagesDetails';
import { businessServices } from 'src/services';
import { BusinessStatusConstant } from 'src/utils/Constants';
import { getCategoryName } from 'src/utils/CommonFunctions';


const ShopDetailsAdminView = () => {
  const { shopId } = useParams();
  const {getCategoriesList, toasterMsg, setToasterMsg, user, categories} = useContext(AuthContext);
  const [showLoader, setLoader] = useState(false);
  const [shopDetailsInfo, setShopDetailsInfo] = useState({});
  const [categoryName, setCategoryName] = useState(null);
  const [shopContacts, setShopContacts] = useState([]);
  const [shopImages, setShopImages] = useState([]);
  const {submitted, pending, published} = BusinessStatusConstant;
  const navigate = useNavigate();

  useEffect(()=>{
    getCategoriesList();
   if(shopId) getBusinessDetails();
  },[shopId]);

  useEffect(()=>{
    if(categories?.length > 0 && shopDetailsInfo?.category_id){
      const findCategory = getCategoryName(categories, shopDetailsInfo);
      console.log('findCategory', findCategory);
      setCategoryName(findCategory);
    };
  },[shopDetailsInfo, categories]);
  

  const getBusinessDetails = () => {
    // const businesses = mockBusinessList;
    // setBusinessList(businesses);
    setLoader(true);
    businessServices.GetShopDetailsById({shop_id: shopId})
    .then((res) => {
        const { data } = res;
        if (data?.status) {
          const shops = data?.data;
         console.log('shopsshopsshopsshops', shops);
         if(shops?.contacts?.length>0 ) setShopContacts(shops.contacts);
         if(shops?.images?.length>0 ) setShopImages(shops.images);
          setShopDetailsInfo(shops);
        } else {
          setShopDetailsInfo([]);
        }
        setLoader(false);
    })
    .catch((e) => {
        console.log('business list err', e);
        let msg = e?.response?.data?.message;
        if (!msg && e?.response?.statusText) {
          msg = e?.response?.statusText;
        }
       setToasterMsg({ message: msg, error: true });
       setLoader(false);
    });
  };

  const navigateToAdd = () => {
    const url = UrlConstants.routeUrls.addShop;
    shopDetailsInfo ? navigate(url, { state: { shopDetailsInfo } }) : navigate(url);
  };

  const renderShopAccordians = () => {
    return(
      <CAccordion activeItemKey={1}>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader>Basic Shop Detalis</CAccordionHeader>
                <CAccordionBody>
                  <BasicShopDetails shopDetailsInfo={shopDetailsInfo} categoryName={categoryName}/>
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={2}>
                <CAccordionHeader>Shop Contacts</CAccordionHeader>
                <CAccordionBody>
                  <ShopContactDetails shopContacts={shopContacts} />
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={3}>
                <CAccordionHeader>Shop Images</CAccordionHeader>
                <CAccordionBody>
                  <ShopImagesDetails shopImages={shopImages}/>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
    );
  };

  return (
    <CRow>
      <Toaster show={!!toasterMsg.message} colorScheme={toasterMsg.error ? 'danger' : 'success'} message={toasterMsg.message} onToasterClose={() => setToasterMsg('')} autoHide={true}/>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader style={{display:'flex',justifyContent:'space-between'}}>
            <strong>Shop Details</strong>
            {/* <CButton className='btn btn-primary m-2' onClick={navigateToAdd}>{(shopDetailsInfo?.active_status === submitted || shopDetailsInfo?.active_status === published) ? 'Edit' :'Add'}</CButton> */}
          </CCardHeader>
          <CCardBody>
            {(!shopDetailsInfo || shopDetailsInfo?.active_status === pending) &&<NoRecordFound message='Please add your shop details...'/>}
            {(shopDetailsInfo?.active_status === submitted || shopDetailsInfo?.active_status === published) && renderShopAccordians()}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default ShopDetailsAdminView;
