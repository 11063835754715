import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from 'react-router-dom';

// routes config
import {AgentRoutes, MainRoutes, VendorRoutes} from '../routes';
import { AuthContext } from "src/context";
import { UserTypeConstant } from "src/utils/Constants";
const MainNavigations = () => {
  const { token, user } = useContext(AuthContext);
  const [userRoutes, setUserRoutes] = useState();
  const userRoll = user?.role_id;
  useEffect(()=>{
    // token && setNavigationPannel();
    setNavigationPannel();
  },[token]);

  useEffect(()=>{
    // token && setNavigationPannel();
    // setNavigationPannel();
    console.log('userRoutes******', userRoutes);
  },[userRoutes]);

  const setNavigationPannel = () => {
    switch(userRoll){
      case 'admin':
        setUserRoutes(MainRoutes);
        break;
      case 'agent':
        setUserRoutes(AgentRoutes);
        break;
      case 'vendor':
        setUserRoutes(VendorRoutes);
        break;
      default: 
       setUserRoutes(VendorRoutes);
      break;
    }
  }; 

    return(
        <Routes>
          {userRoutes?.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
          <Route path="*" element={<Navigate to={userRoll === UserTypeConstant.vendor ? 'profile' : 'dashboard'} replace />} />
        </Routes>
    );
};

export default MainNavigations;
