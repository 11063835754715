import { CButton, CFormInput } from "@coreui/react";
import React from "react";
import PropTypes from 'prop-types'; // Import prop-types
import CIcon from "@coreui/icons-react";
import {
  cilSearch
} from '@coreui/icons';
import { propTypes } from "react-bootstrap/esm/Image";


const PageSearchHeader = (props) => {
    const { handleDownloadExcel, handleUpdateSearch, getList, business_name } = props;
    return(
        <div className="row">
            <div className="col-sm-12 col-md-6 mb-2">
              <div className="dt-buttons btn-group flex-wrap">
                <button className="btn btn-secondary buttons-excel buttons-html5" tabIndex="0" aria-controls="example1" type="button" onClick={handleDownloadExcel}><span>Excel</span></button> 
                {/* <button className="btn btn-secondary buttons-pdf buttons-html5" tabIndex="0" aria-controls="example1" type="button"><span>PDF</span></button>  */}
                {/* <button className="btn btn-secondary buttons-print" tabIndex="0" aria-controls="example1" type="button"><span>Print</span></button>  */}
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mb-2 d-flex align-items-center">
              <div id="example1_filter" className="dataTables_filter form-floating flex-grow-1 me-2">
                <CFormInput
                  placeholder="Search"
                  aria-label="Input group example"
                  aria-describedby="btnGroupAddon"
                  className='form-control form-control-md'
                  name="business_name"
                  onChange={handleUpdateSearch}
                  value={business_name}
                />
                <label htmlFor="search">Search</label>
              </div>
              <CButton className="flex-shrink-0" onClick={getList}>Search <CIcon icon={cilSearch} className="ms-2" /></CButton>
            </div>
          </div>
    );
};

export default PageSearchHeader;

PageSearchHeader.propTypes = {
  handleDownloadExcel: PropTypes.any,
  handleUpdateSearch: propTypes.any,
  getList: propTypes.any,
  business_name: propTypes.any
};
