import { api } from './axiosConfig';
import { UrlConstants } from 'src/utils/UrlConstants';
const {apiUrls} = UrlConstants;

// const token = localStorage.getItem('token');

// Set the token in the request headers
// api.defaults.headers['x-access-token'] = token;
// api.defaults.headers.common['x-access-token'] = token;

export const CreatePackage = async(data) => {
    return await api.post(apiUrls.createPackage, data);
};

export const GetAllPackages = async() => {
    return await api.get(apiUrls.getAllPackages);
};

export const CreateCategory = async(data) => {
    return await api.post(apiUrls.createCategory, data);
};

export const GetAllCategories = async() => {
    return await api.get(apiUrls.getAllCategories);
};

export const GetAllShops = async() => {
    return await api.get(apiUrls.getAllShops);
};

export const CreateShop = async() => {
    return await api.post(apiUrls.createShopDetails);
};

export const EditShopDetails = async(data, params) => {
    return await api.patch(apiUrls.editShopDetails, data, {params});
};
// /api/shop/editShopRecord?id=2 
//getShopDetailsByOwnerId getShopDetailsByCategoryId  getShopListByStatus getShopDetailsById
export const GetShopDetailsByOwner = async(params) => {
    return await api.get(apiUrls.getShopDetailsByOwnerId, {params});
};

export const GetShopDetailsByCategory = async(params) => {
    return await api.get(apiUrls.getShopDetailsByCategoryId, {params});
};

export const GetShopListByStatus = async(params) => {
    params.page = 1;
    params.page_size = 100;
    return await api.get(apiUrls.getShopListByStatus, {params});
};

export const GetShopDetailsById = async(params) => {
    return await api.get(apiUrls.getShopDetailsById, {params});
};

export const UploadShopImages = async(data, params) => {
    return await api.post(apiUrls.uploadShopImages, data, {params});
};

export const UpdateShopLogoImages = async(data, params) => {
    return await api.patch(apiUrls.updateShopLogoImages, data, {params});
};

export const removeShopImage = async(params) => {
    return await api.delete(apiUrls.removeImage, {params});
};


// api/shop/uploadImages?type=image&shop_id=1


// /api/shop/updateShopImageAndThumbnail?shop_id=1