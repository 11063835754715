import { CForm, CFormFloating, CFormInput, CFormLabel } from "@coreui/react";
import React from "react";
import PropTypes from 'prop-types';

const BankDetails = (props) => {

    const { bankingDetails, updateBankDetails, isEdit = false } = props;
    // const { updateUserDetails, userDetails, userType, isEdit = false, invalidFields } = props;
    {/*
        id
        userid
        accNo
        ifscCode
        branch
        cancelCheckUrl
        panCardUrl
        addressProofUrl
    */}
    // user_id  bank_name account_number ifsc_code branch cancel_check_url pan_card_url address_proof_url 

    return(
            <div className="from-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="text" id="bank_name" placeholder="Enter bank name" value={bankingDetails?.bank_name} onChange={updateBankDetails}  name="bank_name"
                         feedbackInvalid="Please enter a bank name."
                         required
                         tooltipFeedback  
                        />
                        <CFormLabel htmlFor="bank_name">Bank Name</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="number" id="account_number" placeholder="Account Number"  value={bankingDetails?.account_number} onChange={updateBankDetails}
                         feedbackInvalid="Please enter a account number." name="account_number"
                         required
                         tooltipFeedback  
                        />
                        <CFormLabel htmlFor="account_number">Account Number</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="text" id="ifsc_code" placeholder="ifsc code"  value={bankingDetails?.ifsc_code} onChange={updateBankDetails} name="ifsc_code"
                         feedbackInvalid="Please enter a ifsc code."
                         required
                         tooltipFeedback  
                         
                        />
                        <CFormLabel htmlFor="ifsc_code">IFSC Code</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="text" id="branch" placeholder="Branch Name"  value={bankingDetails?.branch } onChange={updateBankDetails} name="branch"
                         feedbackInvalid={"Please enter a branch name."}
                         required
                         tooltipFeedback 
                         
                        />
                        <CFormLabel htmlFor="branch">Branch Name</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                         <CFormFloating className="mb-3">
                        <CFormInput type="file" id="cancel_check_url" placeholder="Select cancel check"  value={bankingDetails?.cancel_check_url} onChange={updateBankDetails} 
                         feedbackInvalid={"Please select cancel check"} name="cancel_check_url"
                         required
                         tooltipFeedback
                        />
                        <CFormLabel htmlFor="cancel_check_url">Select Cancel Check</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="file" id="pan_card_url" placeholder="Select pan card"  
                        value={bankingDetails?.pan_card_url} 
                        onChange={updateBankDetails} name="pan_card_url"
                        feedbackInvalid={"Please select pan card"}
                        required
                        tooltipFeedback
                        />
                        <CFormLabel htmlFor="pan_card_url">Select Pan Card</CFormLabel>
                        </CFormFloating>
                    </div>
                    <div className="col-md-6">
                        <CFormFloating className="mb-3">
                        <CFormInput type="file" id="adharNo" placeholder="Select Address proof"  
                        value={bankingDetails?.address_proof_url} 
                        onChange={updateBankDetails} 
                        name="address_proof_url" required/>
                        <CFormLabel htmlFor="address_proof_url">Select Address Proof</CFormLabel>
                        </CFormFloating>
                    </div>
                 </div>
            </div>
    );
};

export default BankDetails;

BankDetails.propTypes = {
    bankingDetails: PropTypes.any,
    updateBankDetails: PropTypes.func.isRequired,
    isEdit: PropTypes.boolean
};
