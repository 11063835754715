import { createContext } from "react";
  
// Create AuthContext with initial values
const AuthContext = createContext({
    user: null,
    login: () => {},
    logout: () => {},
    getUserDetails: () => {},
    getCategoriesList:() => {},
    authApiErrMsg: null,
    token: null,
    toasterMsg: '',
    setToasterMsg: () => {},
    categories: null
  });

  export default AuthContext;
