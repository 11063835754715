import React from 'react';
import PropTypes from 'prop-types';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';

const ConfirmationModal = (props) => {

    const { open, handleClose, handleConfirm, title='Are you sure', message='Are you sure about this action' } = props;

    return (
        <>
        <CModal
            visible={open}
            onClose={handleClose}
            aria-labelledby="LiveDemoExampleLabel"
          >
            <CModalHeader onClose={handleClose}>
              <CModalTitle id="LiveDemoExampleLabel">{title}</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <p>{message}</p>
            </CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={handleClose}>
                Close
              </CButton>
              <CButton color="primary" onClick={handleConfirm}>Cofirm</CButton>
            </CModalFooter>
          </CModal>
        </>
      )
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
    open: PropTypes.boolean,
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string
};

