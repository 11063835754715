export const getToken = () => {
    const localToken = localStorage.getItem('token');
    return localToken;
};

export const getExpiresAt = () => {
    const expiresAt = localStorage.getItem('expiresAt');
    return expiresAt;
};

export const getUser = () => {
    const user = localStorage.getItem('user');
    return user;
};
