import { CButton } from '@coreui/react';
import PropTypes from 'prop-types';
import React from 'react';

const TableActionButtons = (props) => {
    const {editBtntext, deleteBtnText, editBtnAction, deleteBtnAction, style, classNames} = props;
    const editButtonnText = editBtntext ? editBtntext: 'Edit';
    const deleteButtonnText = deleteBtnText ? editBtnText: 'Deactive';


    return(
        <>
         {/* <CButton className='m-2' onClick={editBtnAction}>{editButtonnText}</CButton> */}
         <CButton color='danger' onClick={deleteBtnAction}>{deleteButtonnText}</CButton>
        
        </>
    );

};

export default TableActionButtons;

TableActionButtons.propTypes = {
    editBtntext: PropTypes.string, // Required string prop for edit button text
    deleteBtnText: PropTypes.string.isRequired, // Required string prop for delete button text
    editBtnAction: PropTypes.func.isRequired, // Required function prop for edit button action
    deleteBtnAction: PropTypes.func.isRequired, // Required function prop for delete button action
    style: PropTypes.object, // Optional style prop, object type
    classNames: PropTypes.string // Optional classNames prop, string type
};
