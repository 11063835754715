import React, { Component, Suspense, useContext } from 'react';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import { AuthNavigations } from '.';
import DefaultLayout from 'src/layout/DefaultLayout';
import { AuthContext, AuthProvider } from 'src/context';

const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

const Navigations = () => {
    const { token, user } = useContext(AuthContext);
  console.log('user******', token);
  const isAuthenticated = !!token; //TODO: check to not work for null value, right now its working on null as well
  // const isAuthenticated = true;

    return(
        <Suspense fallback={loading}>
        <Routes>
          {
            !isAuthenticated && <Route path="*" name="Login" element={<AuthNavigations />} />
          }
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    );
};

export default Navigations;
