import React, { useContext } from 'react';
import {
  CRow,
  CCol,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CWidgetStatsA,
} from '@coreui/react';
import { getStyle } from '@coreui/utils';
import { CChartBar, CChartLine } from '@coreui/react-chartjs';
import CIcon from '@coreui/icons-react';
import { cilArrowBottom, cilArrowTop, cilOptions } from '@coreui/icons';
import { AuthContext } from 'src/context';
import { UserTypeConstant } from 'src/utils/Constants';

const DashboardWidgets = () => {
  const { user } = useContext(AuthContext);
  console.log('userdashboard', user); 

  const rednerAdminWidgets = () => {
  return(
    <>
        <CCol sm={6} lg={3}>
        <CWidgetStatsA
            className="mb-4"
            color="primary"
            value={
            <>
                26K{' '}
                <span className="fs-6 fw-normal">
                (-12.4% <CIcon icon={cilArrowBottom} />)
                </span>
            </>
            }
            title="Agents"
            chart={
            <CChartLine
                className="mt-3 mx-3"
                style={{ height: '70px' }}
                data={{
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                    label: 'My First dataset',
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(255,255,255,.55)',
                    pointBackgroundColor: getStyle('--cui-primary'),
                    data: [65, 59, 84, 84, 51, 55, 40],
                    },
                ],
                }}
                options={{
                plugins: {
                    legend: {
                    display: false,
                    },
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                    },
                    },
                    y: {
                    min: 30,
                    max: 89,
                    display: false,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                    },
                },
                elements: {
                    line: {
                    borderWidth: 1,
                    tension: 0.4,
                    },
                    point: {
                    radius: 4,
                    hitRadius: 10,
                    hoverRadius: 4,
                    },
                },
                }}
            />
            }
        />
        </CCol>
        <CCol sm={6} lg={3}>
        <CWidgetStatsA
            className="mb-4"
            color="info"
            value={
            <>
                $6.200{' '}
                <span className="fs-6 fw-normal">
                (40.9% <CIcon icon={cilArrowTop} />)
                </span>
            </>
            }
            title="Vendors"
            chart={
            <CChartLine
                className="mt-3 mx-3"
                style={{ height: '70px' }}
                data={{
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                    label: 'My First dataset',
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(255,255,255,.55)',
                    pointBackgroundColor: getStyle('--cui-info'),
                    data: [1, 18, 9, 17, 34, 22, 11],
                    },
                ],
                }}
                options={{
                plugins: {
                    legend: {
                    display: false,
                    },
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                    },
                    },
                    y: {
                    min: -9,
                    max: 39,
                    display: false,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                    },
                },
                elements: {
                    line: {
                    borderWidth: 1,
                    },
                    point: {
                    radius: 4,
                    hitRadius: 10,
                    hoverRadius: 4,
                    },
                },
                }}
            />
            }
        />
        </CCol>
        <CCol sm={6} lg={3}>
        <CWidgetStatsA
            className="mb-4"
            color="warning"
            value={
            <>
                2.49{' '}
                <span className="fs-6 fw-normal">
                (84.7% <CIcon icon={cilArrowTop} />)
                </span>
            </>
            }
            title="Distributors"
            chart={
            <CChartLine
                className="mt-3"
                style={{ height: '70px' }}
                data={{
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                    label: 'My First dataset',
                    backgroundColor: 'rgba(255,255,255,.2)',
                    borderColor: 'rgba(255,255,255,.55)',
                    data: [78, 81, 80, 45, 34, 12, 40],
                    fill: true,
                    },
                ],
                }}
                options={{
                plugins: {
                    legend: {
                    display: false,
                    },
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                    display: false,
                    },
                    y: {
                    display: false,
                    },
                },
                elements: {
                    line: {
                    borderWidth: 2,
                    tension: 0.4,
                    },
                    point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    },
                },
                }}
            />
            }
        />
        </CCol>
        <CCol sm={6} lg={3}>
        <CWidgetStatsA
            className="mb-4"
            color="danger"
            value={
            <>
                44K{' '}
                <span className="fs-6 fw-normal">
                (-23.6% <CIcon icon={cilArrowBottom} />)
                </span>
            </>
            }
            title="Total Business"
            chart={
            <CChartBar
                className="mt-3 mx-3"
                style={{ height: '70px' }}
                data={{
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                    'January',
                    'February',
                    'March',
                    'April',
                ],
                datasets: [
                    {
                    label: 'My First dataset',
                    backgroundColor: 'rgba(255,255,255,.2)',
                    borderColor: 'rgba(255,255,255,.55)',
                    data: [78, 81, 80, 45, 34, 12, 40, 85, 65, 23, 12, 98, 34, 84, 67, 82],
                    barPercentage: 0.6,
                    },
                ],
                }}
                options={{
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                    display: false,
                    },
                },
                scales: {
                    x: {
                    grid: {
                        display: false,
                        drawTicks: false,
                    },
                    ticks: {
                        display: false,
                    },
                    },
                    y: {
                    grid: {
                        display: false,
                        drawBorder: false,
                        drawTicks: false,
                    },
                    ticks: {
                        display: false,
                    },
                    },
                },
                }}
            />
            }
        />
        </CCol>
     </>
    );
  };

  const rednerAgentWidgets = () => {
        return(
        <>
            <CCol sm={6} lg={3}>
            <CWidgetStatsA
                className="mb-4"
                color="primary"
                value={
                <>
                    26K{' '}
                    <span className="fs-6 fw-normal">
                    (-12.4% <CIcon icon={cilArrowBottom} />)
                    </span>
                </>
                }
                title="My Commission"
                chart={
                <CChartLine
                    className="mt-3 mx-3"
                    style={{ height: '70px' }}
                    data={{
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                    datasets: [
                        {
                        label: 'My First dataset',
                        backgroundColor: 'transparent',
                        borderColor: 'rgba(255,255,255,.55)',
                        pointBackgroundColor: getStyle('--cui-primary'),
                        data: [65, 59, 84, 84, 51, 55, 40],
                        },
                    ],
                    }}
                    options={{
                    plugins: {
                        legend: {
                        display: false,
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            display: false,
                        },
                        },
                        y: {
                        min: 30,
                        max: 89,
                        display: false,
                        grid: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                        },
                        },
                    },
                    elements: {
                        line: {
                        borderWidth: 1,
                        tension: 0.4,
                        },
                        point: {
                        radius: 4,
                        hitRadius: 10,
                        hoverRadius: 4,
                        },
                    },
                    }}
                />
                }
            />
            </CCol>
            <CCol sm={6} lg={3}>
            <CWidgetStatsA
                className="mb-4"
                color="info"
                value={
                <>
                    $6.200{' '}
                    <span className="fs-6 fw-normal">
                    (40.9% <CIcon icon={cilArrowTop} />)
                    </span>
                </>
                }
                title="Vendors"
                chart={
                <CChartLine
                    className="mt-3 mx-3"
                    style={{ height: '70px' }}
                    data={{
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                    datasets: [
                        {
                        label: 'My First dataset',
                        backgroundColor: 'transparent',
                        borderColor: 'rgba(255,255,255,.55)',
                        pointBackgroundColor: getStyle('--cui-info'),
                        data: [1, 18, 9, 17, 34, 22, 11],
                        },
                    ],
                    }}
                    options={{
                    plugins: {
                        legend: {
                        display: false,
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            display: false,
                        },
                        },
                        y: {
                        min: -9,
                        max: 39,
                        display: false,
                        grid: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                        },
                        },
                    },
                    elements: {
                        line: {
                        borderWidth: 1,
                        },
                        point: {
                        radius: 4,
                        hitRadius: 10,
                        hoverRadius: 4,
                        },
                    },
                    }}
                />
                }
            />
            </CCol>
            <CCol sm={6} lg={3}>
            <CWidgetStatsA
                className="mb-4"
                color="warning"
                value={
                <>
                    2.49{' '}
                    <span className="fs-6 fw-normal">
                    (84.7% <CIcon icon={cilArrowTop} />)
                    </span>
                </>
                }
                title="Distributors"
                chart={
                <CChartLine
                    className="mt-3"
                    style={{ height: '70px' }}
                    data={{
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                    datasets: [
                        {
                        label: 'My First dataset',
                        backgroundColor: 'rgba(255,255,255,.2)',
                        borderColor: 'rgba(255,255,255,.55)',
                        data: [78, 81, 80, 45, 34, 12, 40],
                        fill: true,
                        },
                    ],
                    }}
                    options={{
                    plugins: {
                        legend: {
                        display: false,
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                        display: false,
                        },
                        y: {
                        display: false,
                        },
                    },
                    elements: {
                        line: {
                        borderWidth: 2,
                        tension: 0.4,
                        },
                        point: {
                        radius: 0,
                        hitRadius: 10,
                        hoverRadius: 4,
                        },
                    },
                    }}
                />
                }
            />
            </CCol>
            <CCol sm={6} lg={3}>
            <CWidgetStatsA
                className="mb-4"
                color="danger"
                value={
                <>
                    44K{' '}
                    <span className="fs-6 fw-normal">
                    (-23.6% <CIcon icon={cilArrowBottom} />)
                    </span>
                </>
                }
                title="Total Business"
                chart={
                <CChartBar
                    className="mt-3 mx-3"
                    style={{ height: '70px' }}
                    data={{
                    labels: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                        'January',
                        'February',
                        'March',
                        'April',
                    ],
                    datasets: [
                        {
                        label: 'My First dataset',
                        backgroundColor: 'rgba(255,255,255,.2)',
                        borderColor: 'rgba(255,255,255,.55)',
                        data: [78, 81, 80, 45, 34, 12, 40, 85, 65, 23, 12, 98, 34, 84, 67, 82],
                        barPercentage: 0.6,
                        },
                    ],
                    }}
                    options={{
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                        display: false,
                        },
                    },
                    scales: {
                        x: {
                        grid: {
                            display: false,
                            drawTicks: false,
                        },
                        ticks: {
                            display: false,
                        },
                        },
                        y: {
                        grid: {
                            display: false,
                            drawBorder: false,
                            drawTicks: false,
                        },
                        ticks: {
                            display: false,
                        },
                        },
                    },
                    }}
                />
                }
            />
            </CCol>
        </>
        );
  };
  
  return (
    <CRow>
        {user?.role_id ===  UserTypeConstant.admin ? 
          rednerAdminWidgets() : 
          rednerAgentWidgets()
        }
    </CRow>
  );
};

export default DashboardWidgets;
