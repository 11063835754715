import React, { useContext, useEffect, useState } from 'react';
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';

import avatar8 from './../../assets/images/avatars/8.jpg';
import admin from './../../assets/images/avatars/admin.png';
import agent from './../../assets/images/avatars/agent.png';
import vendor from './../../assets/images/avatars/vendor.png';

import { AuthContext } from 'src/context';
import { useNavigate } from 'react-router-dom';
import { UrlConstants } from 'src/utils/UrlConstants';
import { UserTypeConstant } from 'src/utils/Constants';

const AppHeaderDropdown = () => {
  const {logout, user} = useContext(AuthContext);
  const navigate = useNavigate();
  const [avtarImg, setAvtarImg] = useState(avatar8);
  const userRoll = user?.role_id;

  useEffect(()=>{
    setUserIcon();
  },[userRoll]);

  const setUserIcon = () => {
    switch(userRoll){
      case 'admin':
        setAvtarImg(admin);
        break;
      case 'agent':
        setAvtarImg(agent);
        break;
      case 'vendor':
        setAvtarImg(vendor);
        break;
      default: 
       setAvtarImg(vendor);
      break;
    }
  }; 
  
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={avtarImg} size="md" />
        {/* <CIcon icon={cilUser} className="me-2 avatar avatar-md" /> */}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Settings</CDropdownHeader>
        <CDropdownItem onClick={() => navigate(UrlConstants.routeUrls.profile)}>
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="#" onClick={logout}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Log Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
